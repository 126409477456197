.testimonials {
  h2 {
    color: $alt-colour;
    text-align: center;
    font-weight: 300;
    text-shadow: 1px 1px 1px $mid-shadow-colour;
  }
  .testimonial-wrap {

    .testimonial-text {
      width: 100%;
    }

    h3,
    h4 {
      text-align: right;
      margin: 5px 80px 0 0;
      padding: 0;
      font-weight: 300;
      color: #666;
    }

    h3 {
      font-size: 1.4em;
    }

    h4 {
      font-size: 1.1em;
    }


    p {
      font-size: 20px;
      line-height: 20px;
      font-style: italic;
      margin: 0;
      padding: 0 20px;
      color: #666;
    }

    @include media ($medium-screen) {

      h3,
      h4 {
        margin: 5px 100px 0 0;
      }

      p {
        padding: 0 80px;
      }
    }
  }
}
