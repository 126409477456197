.maps {
  @include clearfix;
  padding-bottom: 10px;

  #map_canvas,
  #map_canvas2 {

    @include border-radius(10px);
    @include border-color($border-color);
    @include border-width($border-size);
    @include border-style(solid);

    width: 100%;
    height: 400px;

    img {
      max-width: none; // to prevent UI control bug
      border-radius: 0;
      box-shadow: none;
    }

    .content {
      padding: .7em .3em 1em .3em;
    }

    .firstHeading {
      font-size: 1.8em;
      margin-bottom: 0;
    }

    .bodyContent p {
      font-size: 1em;
      line-height: 1.3;
      margin-bottom: 0;
    }

    .bodyContent p:last-of-type {
      border-top: 1px solid silver;
      margin-top: .5em;
      padding-top: .3em;
    }
  }

}

