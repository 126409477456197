.contact {
  .contact-col {

    h3 {
      margin: 0;

      &.telnum {
        margin: 10px 0;
      }

      &.email a {
        text-shadow: 1px 1px 2px $shadow-colour;
      }
    }

    @include media ($large-screen) {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  .contactform {

    .booking-form,
    .contact-form {
      display: block;
      margin-left: auto;
      margin-right: auto;

      .formgroup {
        display: block;

        label {
          text-shadow: 1px 1px 1px $dark-shadow-colour;
        }

        @include media ($large-screen) {

          label {
            display: inline-block;
            text-align: right;
            width: 25%;

            @include media ($full-screen) {
              font-size: 1.2em;
            }
          }

          .messagelabel {
            vertical-align: top;
            padding-top: 1em;
          }

          #name, #email, #phonenum, #company, #subject, #message,  #reg, #makemod, #branch, #bookingdate, #workreq {
            display: inline-block;
            width: 70%;
            font-weight: 400;
          }
        }
      }

      .disp-error {
        color: red;
        text-shadow: 1px 1px 1px #000;

        @include media ($large-screen) {
          margin-left: 30%;
        }
      }

      .buttons {
        text-align: right;

        .submit-btn {
          @include border-radius(7px);
          @include border-color($border-color);
          @include border-width($border-size);
          @include border-style(solid);
          @include transition(all 0.3s ease-in-out);
          @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
                  radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
          @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s );

          cursor: pointer;
          color: $main-colour;
          background-color: $navigation-background-hover;
          font-weight: 500;
          margin: 5px 0;
          padding: 10px 25px;
          display: inline-block;
          line-height: 1;
          text-shadow: 1px 1px 1px #000;
          box-shadow: black 2px 2px 5px;

          &:hover {
            color: $navigation-color-hover;
            font-weight: 500;
            text-shadow: 1px 1px 1px #ccc;
          }

          @include media ($large-screen) {
            margin-right: 20%;
          }
        }
      }
    }
  }
}
