header {
  @include box-sizing(border-box);

  margin-left: 10px;
  margin-right: 10px;

  @include media ($large-screen) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .headerwrap {
    @media screen and (max-width: $full-screen) {
      @include clearfix();
    }
  }

  .logo {
    text-align: left;
    width: 100%;
    padding: 5px 0 5px;

    h1 {
      line-height: 0;
      margin: 0;
    }

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
    }

    @include media ($medium-screen) {
      float: left;
      text-align: left;
      width: 50%;

      img {
        margin: 0;
      }
    }

  }


  .rightheader {
    text-align: center;
    width: 100%;

    h2 {
      font-size: 2.8em;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 5px;
      margin-top: 0;
      text-shadow: 1px 1px 2px $mid-shadow-colour;
    }

    h3 {
      font-size: 2.5em;
      font-weight: 100;
      font-style: italic;
      text-align: center;
      line-height: 1;
      margin: 0;
      text-shadow: 1px 1px 2px $mid-shadow-colour;
    }

    h4 {
      font-size: 1.5em;
      font-weight: 400;
      line-height: .8;
      margin-bottom: 10px;
      margin-top: 10px;
      text-shadow: 1px 1px 2px $shadow-colour;
    }

    @include media ($medium-screen) {
      float: left;
      padding-top: 20px;
      text-align: center;
      width: 50%;

      h2 {
        margin: 0;
      }

      h3 {
        font-size: 1.7em;
        margin-left: 20%;
        margin-right: 20%;
      }

      h4 {
        font-weight: 400;
        line-height: .8;
        margin-top: 0;
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    @include media ($large-screen) {

      h3 {
        font-size: 2.3em;
      }

      h4 {
        font-size: 2em;
      }

        h2 {
        font-size: 3.8em;
        margin-bottom: 0;
      }
    }

    @include media ($full-screen) {
      height: 140px;

      h4 {
        font-size: 2.5em;
      }

      h3 {
        font-size: 2.5em;
        margin-left: 0;
        margin-right: 0;
        margin-top: 5px;
      }
    }
  }
}
