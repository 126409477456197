.modal {
  $base-border-color: #dcdcdc !default;
  $base-border-radius: 10px !default;
  $base-background-color: #fff !default;
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $action-color: #477dca !default;
  $dark-gray: #333 !default;
  $light-gray: #ddd !default;
  $medium-screen: em(640) !default;
  $large-screen: em(860) !default;
  $base-font-color: $dark-gray !default;
  $modal-padding: 3em;
  $modal-background: $base-background-color;
  $modal-close-color: $light-gray;
  $modal-image-height: 135px;
  $modal-image-width: $modal-image-height;
  $modal-trigger-image-width: 300px;

  .modal-fade-screen { // overlay
    @include transition(opacity 0.25s ease);
    @include position(fixed, 0);
    background-color: rgba(#000, 0.85);
    opacity: 0;
    padding-top: 0.6em;
    text-align: left;
    visibility: hidden;
    z-index: 99999999999;

    @include media($large-screen) {
      padding-top: 10em;
    }

    .modal-bg {
      @include position(absolute, 0);
      cursor: pointer;
    }
  }

  .modal-state {
    display: none;
  }

  .modal-close {
    @include position(absolute, ($modal-padding /2) ($modal-padding /2) null null);
    @include size(1.5em);
    background: $modal-background;
    cursor: pointer;

    &:after,
    &:before {
      @include position(absolute, 3px 3px 0 50%);
      @include transform(rotate(45deg));
      @include size(0.15em 1.5em);
      background: $modal-close-color;
      content: '';
      display: block;
      margin: -3px 0 0 -1px;
    }

    &:hover:after,
    &:hover:before {
      background: darken($modal-close-color, 10%);
    }

    &:before {
      @include transform(rotate(-45deg));
    }
  }

  .modal-inner {
    @include transition(opacity 0.25s ease);
    background: $modal-background;
    border-radius: $base-border-radius;
    margin-top: 0;
    margin: auto;
    max-height: 95%;
    overflow: auto;
    padding: $modal-padding / 2;
    position: relative;
    width: 95%;
    max-width: 500px;

    @include media($medium-screen) {
      max-height: 85%;
      padding: $modal-padding;
      width: 60%;
    }

    @include media($large-screen) {
      width: 50%;
    }

    h1 {
      font-size: 1.4em;
      color: $base-font-color;
      margin-bottom: 0 0 0.6em 0;
      text-align: left;
      text-transform: capitalize;

      @include media($large-screen) {
        font-size: 1.6em;
      }
    }

    p {
      color: $base-font-color;
      line-height: $base-line-height;
    }

    .modal-intro {
      margin-bottom: 1em;
    }

    .modal-content {
      color: $base-font-color;

      @include media($medium-screen) {
        @include columns(2 8em);
      }
    }

    a.cta {
      color: $base-background-color;
      display: inline-block;
      margin-right: 0.5em;
      margin-top: 1em;

      &:last-child {
        padding: 0 2em;
      }
    }
  }

  .modal-state:checked + .modal-fade-screen {
    opacity: 1;
    visibility: visible;
  }

  .modal-state:checked + .modal-fade-screen .modal-inner {
    top: 0.5em;
  }

  .error {
    color: red;
    line-height: 1;
    margin-top: -5px;
  }
}

.modal-open {
  overflow: hidden;
}