footer {
  margin: 10px;

  @include media ($large-screen) {
    margin: 10px 20px;
  }

  .footer {
    @include clearfix;
    @include border-radius(10px);
    @include border-color($border-color);
    @include border-width($border-size);
    @include border-style(solid);

    box-shadow: black 2px 2px 7px;
    background-color: $nav-footer-background-color;
    color: $navigation-color;
    text-align: center;
    padding: 0 10px;
    text-shadow: 1px 1px 1px #000;

    @include media ($medium-screen) {
      @include background-image(linear-gradient(hsla(0, 100%, 100%, .4) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 70%),
              radial-gradient(darken($navigation-background, 15%), darken($navigation-background, 5%)));

      padding-top: 5px;
    }

    li {

      & span {
        display: none;
      }

      @include media ($large-screen) {
        display: inline;

        &:first-child {
          margin-left: -10px;
        }

        & span {
          display: inline-block;
        }
      }

      a {
        @include transition(all 0.3s ease-in-out);

        color: $navigation-color;
        font-weight: 500;
        text-shadow: 1px 1px 1px #000;
        display: block;
        font-size: .9em;
        padding-top: 10px;

        &:hover {
          color: $navigation-color-hover;
        }

        @include media ($large-screen) {
          display: inline-block;
          font-size: 1em;
          padding: 0;
          width: 10%;
        }
      }
    }
  }

  .credwrap {
    margin: 10px 0;

    @include media ($large-screen) {
      margin: 0;
    }
  }
  .copy,
  .credit {
    color: #ffffff;
    font-size: 0.8em;
    text-align: center;

    p {
      margin: 0;
    }

    a {
      color: $alt-colour;
      display: inline-block;

      &:hover {
        @include transition(all 0.3s ease-in-out);
        color: #ffffff;
      }
    }

    @include media ($large-screen) {
      @include span-columns(6);

      p {
        margin-bottom: 10px;
      }
    }
  }

  .copy {
    @include media ($large-screen) {
      text-align: left;
    }
  }

  .credit {
    @include media ($large-screen) {
      text-align: right;
    }
  }

}

.wrapper {
  @include clearfix;
}
