.content {
  @include clearfix;
  font-size: .8em;
  font-weight: 300;
  padding: 0 10px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);

  p {
    font-size: 1.2em;
    margin-bottom: 0.5em;
    &.callout {
      text-align: center;
      font-weight: 400;
      color: $alt-colour;
      text-shadow: 1px 1px 1px #000;
    }
  }

  h2 {
    font-weight: 300;
    color: $alt-colour;
    margin-top: 10px;
    margin-bottom: 5px;
    text-shadow: 1px 1px 1px #000;
  }

  h3,
  h4 {
    font-weight: 400;
    margin: 0;
  }

  h6 {
    line-height: 1.4;
    font-size: 1.3em;
    font-weight: 400;
  }

  .note {
    font-size: .7em;
    font-weight: normal;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);
  }

  a {
    @include transition(all 0.3s ease-in-out);
    font-weight: 300;
    color: $alt-colour;

    &:hover {
      color: darken($alt-colour, 15%);
      font-weight: 400;
    }
  }

  .indent {
    margin-left: 20px;
  }

  @include media ($large-screen) {
    padding: 0 20px;

    h6 {
      font-size: 1.4em;
    }

    p {
      font-size: 1.3em;
    }
  }

  @include media ($full-screen) {
    h6 {
      font-size: 1.6em;
    }

    p {
      font-size: 1.4em;
    }
  }

  .main-col {

    p {
      font-size: 1.2em;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
      margin-left: 15px;
    }


    @include media ($large-screen) {
      @include span-columns(9);
    }
  }

  .aside {

    .payments {
      p {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
      }
      img {
        border: none;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
      }
    }

    @include media ($large-screen) {
      @include span-columns(3);

      .openhours {
        p {
          font-size: .9em;
          padding: 5px 0 5px 2px;
        }
      }

      .payments {
        p {
          font-size: 1em;
        }
      }
    }

    @include media ($full-screen) {

      .openhours {
        p {
          font-size: 1.1em;
          padding: 5px;
        }
      }
    }
  }

  aside {
    text-align: center;

    h2 {
      text-align: left;
    }

    @include media ($large-screen) {
      text-align: left;
    }
  }

  .contact-details {
    max-width: 450px;
    margin: auto;
  }

  img {
    border: solid $border-size $alt-colour;
    border-radius: 10px;
    box-shadow: black 2px 2px 7px;
    display: block;
    height: auto;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding: 3px;

    &.fright {
      margin: 10px auto;

      @include media ($large-screen) {
        float: right;
        margin: 10px 0 10px 10px;
        max-width: 45%;
      }
    }

    &.info-fright {
      margin: 10px auto;

      @include media ($medium-screen) {
        float: right;
        margin: 10px 0 10px 10px;
        max-width: 45%;
      }
    }

    &.fleft {
      margin: 10px auto;

      @include media ($large-screen) {
        float: left;
        margin: 10px 10px 10px 0;
        max-width: 35%;
      }
    }

    &.divider {
      border: 0;
      box-shadow: none;
      padding: 0;
      margin: 0 auto 10px;
      width: 85%;
    }
  }

  .grid {

    h5 {
      span,
      label {
        color: $alt-colour;
        text-shadow: 1px 1px 1px black;
        display: inline;
      }

      label {
        cursor: pointer;

        &:hover {
          color: darken($alt-colour, 15%);
        }
      }
    }

    h3 {
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 0.5em;
  }

  @include media ($large-screen) {
    padding: 0 20px;
  }

  article {
    padding-bottom: 5px;
    padding-top: 10px;

    &.col2 {
      @include media ($large-screen) {
        @include span-columns(6);
        @include omega(2n);
      }
    }

    &.col3 {
      a {
        color: $base-font-color;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);

        p {
          font-weight: 300;
        }

        .readmore {
          font-weight: 500;
          color: $alt-colour;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
        }

        &:hover {
          .readmore {
            color: lighten($alt-colour, 15%);
          }
        }
      }


      @include media ($large-screen) {
        @include span-columns(4);
        @include omega(3n);

        img {
          border: solid 2px $alt-colour;
          border-radius: 7px;
        }
      }
    }

    &.col4 {
      @include media ($large-screen) {
        @include span-columns(3);
        @include omega(4n);
      }
    }
  }

  .fb-wrapper {
    margin: 20px auto 0;
    max-width: 510px;
    border: solid 2px $alt-colour !important;
    padding: 3px;
    border-radius: 7px;
    box-shadow: black 2px 2px 7px;

    iframe {
      border-radius: 4px;
    }
  }
}

.radio {
  text-align: center;

  h2,
  audio {
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;
  }

  h3 {
    font-size: 2.2em;
    font-weight: 200;

    @include media ($medium-screen) {
      font-size: 3em;
    }
  }
}