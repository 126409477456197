body {
  background-image: url('/images/background.png'), linear-gradient(darken($alt-colour, 16.6%) 0%, lighten($alt-colour, 9.5%) 40%, lighten($alt-colour, 9.5%) 60%, darken($alt-colour, 16.6%) 100%) !important;
  background-position: center top;
  background-repeat: repeat-y;
  background-color: lighten($alt-colour, 9.5%);

  .main {
    @include outer-container;
    background-color: rgba($content-background-color, 1);
    background-position: center top;
    background-repeat: repeat;
    min-height: 100vh;
  }
}

.tags {
  text-align: center;
  display: none;
}

